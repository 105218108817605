import { BaseListView } from '../smart_bps/bps/list_view'
import { stateToProps } from '../smart_bps/bps/base_view'
import { wrapView, format_datetime } from '../smart_bps/lib/utils'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Paginator } from '../smart_bps/lib/components'


class ServiceListView extends BaseListView {
  constructor(props) {
    super(props)
    this.renderDocument = this.renderDocument.bind(this)
  }

  renderDocument(item) {
    const that = this

    function render_info(val, icon, wrapper_class) {
      const wrapper_class_ = wrapper_class ? wrapper_class : 'me-2'
      // Все это чтобы блок переносился целиком,
      // а не иконка отдельно и контент отдельно
      return val && <span className={wrapper_class_} style={{ whiteSpace: "nowrap" }}>
        {icon && <i className={`ti ti-xs ti-${icon} me-1`}></i>}
        <span style={{ whiteSpace: "normal" }}>{val}</span>
      </span>
    }

    const state_class = classNames("me-2",
      { "text-danger": item.state == "fix_work_result" },
      { "text-success": item.state == "process" },
    )

    const service_status_class = classNames("me-2",
      { "text-danger": item.service_status_id ? item.service_status_id != "Выполнение" : false },
      { "text-success": item.service_status_id == "Выполнение" },
    )

    return (
      <div className="card mb-3" key={item.id}>
        <div className="card-body p-2">
          <span className='me-3'>
            <i className="ti ti-xs ti-cog me-1"></i>
            <Link to={`/service/${item.id}/`} className='me-1'>
              #{item.id} {item.service_type_id}
            </Link>

          </span>

          <div>
            <span className={state_class}>
              <i className='ti ti-xs ti-send'></i> {that.getChoice("bps_service_state", item.state)}
            </span>
            {render_info(item.service_status_id, "activity", service_status_class)}
          </div>
          <div>
            Плановая дата услуги:{" "}
            {item.d_service_plan ? render_info(item.d_service_plan) : render_info(item.d_income)}
          </div>
          <div>
            {render_info(item.auto_owner, "user")}
            {item.phone_list.map((phone_num) => {
              return <div><a href={`tel:${phone_num}`}>{render_info(phone_num)}</a></div>
            })}
          </div>
          <div>
            {render_info(item.car_model_str, "car")}
            {render_info(item.car_model, "car")}
            {render_info(item.plate_number)}
          </div>
          <div>
            {render_info(item.income_place, "map-pin")}
          </div>
        </div>
      </div>
    )
  }

  renderContent() {
    let that = this

    // Мета-данные проекта не пришли
    if (!this.props.project_title) {
      this.initMeta()
      return null
    }

    // Пока список с объектами не пришел, выходим и ждем его
    if (that.props.list_items === undefined) {
      that.updateItems(that.props.list_page)
      return null
    }

    if (this.props.list_items.length === 0) {
      return <div className="card mb-3">
        <div className="card-body p-3">
          Данные не найдены. Уточните условия отбора и поиска.
        </div>
      </div>
    }

    return [
      this.props.list_items.map(that.renderDocument),

      <Paginator current_page={this.props.list_page}
        count_pages={this.props.list_count_pages}
        setPage={this.updateItems} />
    ]
  }

}

export default wrapView(ServiceListView, (state) => {
  return stateToProps(state, 'service')
})
