import { Link } from 'react-router-dom'
import { baseStateToProps, BaseView } from '../lib/base_view'
import { request } from '../lib/utils'
import { store } from '../../index'
import { ModalSearch } from './modal_search'


export class BaseBpsView extends BaseView {
  constructor(props) {
    super(props)
    this.initMeta = this.initMeta.bind(this)
    this.savePreset = this.savePreset.bind(this)
    this.setUserPreset = this.setUserPreset.bind(this)
    this.removeUserSearch = this.removeUserSearch.bind(this)
  }


  get project_name() {
    return this.props.project_name
  }

  initMeta() {
    const that = this

    request({
      method: 'get',
      url: `/api/${this.project_name}/get_meta`,
      success: (data) => {
        that.waitRenderReadyLock = false
        store.dispatch({ type: `${that.project_name}_model_meta`, data })
      },
    })
  }

  savePreset(name) {
    store.dispatch({ type: `${this.project_name}_preset`, data: name })
  }

  setUserPreset(preset) {
    this.onSearch(preset.data)
  }

  removeUserSearch(search_id) {
    const that = this
    request({
      method: 'post',
      url: `/api/${this.project_name}/saved_search_delete/${search_id}`,
      success: (data) => {
        store.dispatch({ type: `${that.project_name}_saved_search`, data })
      },
    })
  }

  renderTopPanelLeftItems() {
    const that = this
    const perms = this.props.perms
    const search_presets = this.props.search_presets

    if (perms === undefined) {
      return null
    }

    const current_preset = this.props.search_preset
    let preset_items = []

    search_presets && search_presets.forEach(item => {
      const preset_name = item[0]
      const preset_title = item[1]

      preset_items.push(<li key={preset_name}>
        <a onClick={() => that.savePreset(preset_name)} className="dropdown-item">
          {preset_name === current_preset && <i className="ti ti-checks me-1"></i>}
          {preset_title} {' '}
        </a>
      </li>)
    })

    // Разделитель между фиксированными отборами и пользовательскими
    if (preset_items.length > 0 && this.props.saved_searches.length > 0) {
      preset_items.push(<li key="header">
        <h6 className="dropdown-header">Ваши настройки поиска</h6>
      </li>)
    }

    this.props.saved_searches.forEach(item => {
      preset_items.push(<li key={item.id}>
        <a class="dropdown-item">
          <span class="d-flex align-items-center align-middle">
            <span class="flex-grow-1 align-middle me-2" onClick={() => { that.setUserPreset(item) }}>
              {item.title}
            </span>
            <span class="flex-shrink-0 d-flex align-items-center justify-content-center" onClick={() => that.removeUserSearch(item.id)}>
              <i className="ti ti-trash-x me-1 text-danger"></i>
            </span>
          </span>
        </a>
      </li>)

    })

    let presets = null;
    if (preset_items.length > 0) {
      presets = <span className="dropdown" key="presets">
        <a className="dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
          <i className="ti ti-filter me-2"></i>
          <span className="d-none d-md-inline-block">Отбор</span>
        </a>
        <ul className="dropdown-menu mt-2">
          <li key="reset">
            <a onClick={() => that.savePreset('')} className="dropdown-item">
              <i className="ti ti-trash-x me-1"></i>
              Сброс
            </a>
          </li>
          {preset_items}
        </ul>
      </span>
    }

    return [
      that.updateItems && <span className="me-2" key="refresh">
        <a onClick={(e) => { that.updateItems(that.props.list_page) }}>
          <i className="ti ti-refresh ti-xs me-1"></i>
          <span className="d-none d-md-inline-block">Обновить</span>
        </a>
      </span>,

      perms.includes("create") && <span className="me-2" key="create">
        <Link to={`/${this.project_name}/new/`} >
          <i className="ti ti-plus ti-xs me-1"></i>
          <span className="d-none d-md-inline-block">Создать</span>
        </Link>
      </span>,

      <span className="me-2" key="search">
        <a data-bs-toggle="modal" data-bs-target="#modal_search">
          <i className="ti ti-search ti-xs me-1"></i>
          <span className="d-none d-md-inline-block">
            Поиск {this.props.list_items_count ? <span>({this.props.list_items_count})</span> : null}
          </span>
        </a>
      </span>,

      presets

    ]
  }

  renderModals() {
    if (this.props.search_form) {
      return <ModalSearch
        project_name={this.project_name}
        schema={this.props.search_form}
        data={this.props.search_data}
        onSearch={this.onSearch}
        choices={this.props.choices}
      />
    }

    return null
  }
}


/**
 * Отображает состояние хранилища redux на props view проекта
 * Вначале получаем props по умолчанию и потом добавляем туда свойства для bps приложения
 */
export function stateToProps(state, project_name) {
  var props = baseStateToProps(state)
  const project_data = state[project_name] || {}

  // метаданные проекта
  props.project_name = project_name
  props.project_title = project_data.project_title
  props.table_headers = project_data.table_headers
  props.search_form = project_data.search_form
  props.search_presets = project_data.search_presets
  props.saved_searches = project_data.saved_searches
  props.form_schema = project_data.form_schema
  props.attrs = project_data.attrs
  props.perms = project_data.perms

  // Данные о состоянии поиска
  props.search_data = project_data.search_data
  props.search_preset = project_data.search_preset

  // Элементов на текущей странице
  props.list_items = project_data.list_items
  props.list_items_count = project_data.list_items_count
  props.list_page = project_data.list_page || 1
  props.list_count_pages = project_data.list_count_pages
  props.list_has_next_page = project_data.list_has_next_page

  return props
}
