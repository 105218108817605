export const initialState = {
  // Мета-данные текущей модели
  project_title: undefined,
  table_headers: undefined,
  search_form: undefined,
  search_presets: undefined,
  saved_searches: undefined,
  form_schema: undefined,
  attrs: undefined,
  perms: undefined,

  // Данные о состоянии поиска
  search_data: undefined,
  search_preset: undefined,

  // Элементов на текущей странице
  list_items: undefined,
  list_items_count: undefined,
  list_page: 1,
  list_count_pages: undefined,
  list_has_next_page: undefined,
}

/**
 * Используется для конкретного bps-проекта "project_name" и генерирует
 * название именно под это приложение динамически
 */
export function storeReducerBuilderChange(builder, project_name) {
  builder

    // Информация по конфигурации модели,
    // которая редактируется в текущий момент
    .addCase(`${project_name}_model_meta`, (state, action) => {
      let data = action.data
      state.project_title = data.title
      state.table_headers = data.table_headers
      state.search_form = data.search_form
      state.search_presets = data.search_presets
      state.form_schema = data.form_schema
      state.attrs = data.attrs
      state.perms = data.perms
      state.saved_searches = data.saved_searches
      state.search_data = undefined
      state.search_preset = undefined
      state.list_items = undefined
      return state
    })

    // Обновить список сохраненных запросов поиска
    .addCase(`${project_name}_saved_search`, (state, action) => {
      state.saved_searches = action.data
      return state
    })

    // Сохраняем данные страницы списка модели
    .addCase(`${project_name}_list_change`, (state, action) => {
      state.list_items = action.data.items
      state.list_page = action.data.page
      state.list_has_next_page = action.data.has_next
      state.list_items_count = action.data.items_count
      state.list_count_pages = action.data.count_pages
      return state
    })

    // Сохраняем данные формы поиска и сбрасываем данные о карточках на странице
    .addCase(`${project_name}_search`, (state, action) => {
      state.search_data = action.data
      state.list_items = undefined
      state.list_page = 1
      return state
    })

    // Сохраняем данные отбора
    .addCase(`${project_name}_preset`, (state, action) => {
      state.search_preset = action.data
      state.list_items = undefined
      state.list_page = 1
      return state
    })

    // Очистка данных на странице (из-за изменений в объекте), чтобы запросить список заново
    .addCase(`${project_name}_list_clean`, (state, action) => {
      state.list_items = undefined
      return state
    })

    .addDefaultCase((state, action) => {
      return state
    })
}
