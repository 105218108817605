/* global toastr moment */

import { connect } from 'react-redux'
import React from 'react'
import { useParams, useLocation } from 'react-router'

import { store } from '../../index'


/**
 * Сначала сделать запрос на обновление ключей аутентификации.
 * Потом повторить запрос, уже с новыми ключами
 */
function refresh_token_and_repeat(config) {
    const refresh_token = localStorage.getItem('auth_refresh_token')
    const refresh_url = localStorage.getItem('auth_refresh_url')

    if(refresh_token === null) {
        console.log("Невозможно обновить токены - отсутствует refresh_token")
        return  // Нет токена, по которому можно обновить, значит не можем обновить
    }

    fetch(refresh_url, {
        method: "get",
        headers: {
            'Authorization': 'bearer ' + refresh_token,
            'Content-Type': 'application/json;charset=utf-8',
        },
        credentials: "omit",
        cache: "no-cache",
        redirect: "error",
    }).then((response) => {
        // Тут все нормально
        if(response.ok) {
            return response.json()
        } else if(response.status === 401) {
            // Аутентификация все, сбросим не валидные ключи
            localStorage.removeItem('auth_refresh_token')
            localStorage.removeItem('auth_refresh_url')

            store.dispatch({ type: 'auth_clean' })
            toastr.warning(
                'Сессия истекла, пожалуйста выполните вход в систему'
            )
        }

    }).then((data) => {
        if(data !== undefined) {
            try {
                localStorage.setItem('auth_access_token', data.access_token)
                localStorage.setItem('auth_refresh_token', data.refresh_token)
                localStorage.setItem('auth_refresh_url', data.refresh_url)

                store.dispatch({ type: 'auth_authenticated', data: data })

                request(config) // Повторить запрос с новыми токенами
            }
            catch(exc) {
                console.log(exc)
                toastr.error("Ошибка при обработке запроса от сервера")
            }
        }
    })
}

export async function request(config) {
    // config: method, url, data, requestType, responseType, success, error

    let requestType = config.requestType !== undefined ? config.requestType : "json"
    let responseType = config.responseType !== undefined ? config.responseType : "json"

    // Заголовки
    let headers;
    if(config.headers !== undefined) {
        headers = config.headers
    }
    else {
        headers = {}
    }
    if(requestType === "json") {
        headers['Content-Type'] = 'application/json;charset=utf-8'
    }

    const auth_token = localStorage.getItem('auth_access_token')
    if (auth_token) {
        headers['Authorization'] = `bearer ${auth_token}`
    }

    // Данные
    let body
    if(config.method === 'GET') {
        body = config.data ? new URLSearchParams(config.data) : undefined
    }
    else if(requestType == "json") {
        body = JSON.stringify(config.data)
    }
    else {
        body = config.data
    }

    let response = await fetch(config.url, {
        method: config.method,
        headers: headers,
        body: body,
        credentials: "omit",
        cache: "no-cache",
        redirect: "error",
    })

    let content;

    // Если запрос не ок и передан обработчик ошибок, выполняем его
    if(!response.ok && config.error !== undefined) {
      config.error(response)
    }

    // Все идет штатно - берем полезную назрузку из запроса и вызываем callback (если он есть конечно)
    if(response.ok) {
        // Если есть callback success
        if(config.success) {
            if(responseType === "json") {
                content = await response.json();
            }
            else if(responseType === "blob") {
                content = await response.blob();
            }

            try {
                config.success(content)
            }
            catch(exc) {
                console.log(exc)
                toastr.error("Сервер вернул результат, но обработать его не получилось", "Ошибка")
            }
        }
    }

    // Какое-то сообщение в json - сервер еще жив и что-то пытается сказать
    else if(response.headers.get('Content-Type') === "application/json") {
        content = await response.json();

        // Токен протух - освежаем и повторим запрос снова
        if(response.status === 401 && content.detail === "token_expired") {
            localStorage.removeItem('auth_access_token')
            refresh_token_and_repeat(config)
        }
        // Какое-то сообщение для вывода в ключе detail (возможно, там тоже объект)
        else if(content.detail !== undefined) {
            toastr.error(JSON.stringify(content.detail), response.statusText)
            console.log(content)
        }

        // Какое-то не понятное сообщение, ну выводим полностью
        else {
            toastr.error(JSON.stringify(content), response.statusText)
            console.log(content)
        }
    }

    // Похоже что сервер в целом не алё. Ну что-то же нужно сообщить. А дальше уже смотрите в логи
    else {
        content = await response.text();
        toastr.error(content, "Внутренняя ошибка системы")
    }
}


/**
 * Обернуть представление во все что только можно
 * - redux
 * - router
 * Гемор в том, что useParams можно использовать только в функциональном компоненте, поэтому
 * все эти пляски с бубном про обертывание компонента класса через функциональный компонент
 **/
export function wrapView(Component, redux_mapper) {

  return connect(redux_mapper)((props) => {
    const url_location = useLocation()
    const url_params = useParams()

    return <Component
      {...props}
      router_params={url_params}
      router_location={url_location.pathname}
    />
  })
}


// Разбить список на n групп
export function split_list(items, cnt) {
  let columns = []
  const items_length = items.length  // Сколько элементов

  // Переключение на колонку для распределения нацело
  let split_idx = Math.floor(items_length / cnt);

  // Не больше чем по одному элементу в колонку
  if (split_idx === 0) {
    split_idx = 1
  }

  // Хвост, который нужно еще раскидать дополнительно к целому распределению
  let tail = items_length - split_idx * cnt

  let start = 0
  for (let index = 1; index <= cnt; index++) {
    let end = start + split_idx

    // Если есть остаток от деления нацело, добавляем его по одному
    if (tail > 0) {
      tail = tail - 1
      end = end + 1
    }

    columns.push(items.slice(start, end))
    start = end  // Сохраняем для следующей итерации
  }

  return columns
}

export function format_datetime(value) {
  if (value) {
    var js_date = new Date(value)
    return moment(js_date).format("DD.MM.YYYY HH:mm")
  }
}

/**
 * Менеджер блокировок. Хранит все в экземпляре.
 * Из модуля экспортируется сам экземпляре
 */
class LockManager {
    constructor() {
        this.locks = new Set();
    }

    set(name) {
        this.locks.add(name)
    }

    remove(name) {
        this.locks.delete(name)
    }

    has(name) {
        return this.locks.has(name)
    }
}

export let lock = new LockManager()


// Форматировать значение какого-то аттрибута для вывода на экран
export function format_attr_value(attr, value) {
    if (attr.data_type === "datetime") {
        return format_datetime(value)
    }
    return value
}


export function get_uuid() {
    // http://www.ietf.org/rfc/rfc4122.txt
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    var uuid = s.join("");
    return uuid;
}


/**
 * Запустить рекурсивную цепочку загрузки файла кусками
 *
 * https://api.video/blog/tutorials/uploading-large-files-with-javascript
 */
export function uploadFile(conf) {
    // Входные аргументы
    const file_item = conf.file_item
    const url = conf.url
    const chunkStart = conf.chunkStart || 0
    const uid = conf.uid || get_uuid()
    const form_args = conf.form_args
    const callback = conf.callback

    // Готовим доп. параметры
    const filesize = file_item.size;
    const filename = file_item.name;
    const chunkSize = 500000;
    let chunkEnd = chunkStart + chunkSize
    let hasNextChunk = true

    // Если это последний кусок
    if (chunkEnd >= filesize) {
        hasNextChunk = false
        chunkEnd = filesize
    }

    // Форма с куском файла
    const chunkForm = new FormData();
    const fileContent = file_item.slice(chunkStart, chunkEnd)
    chunkForm.append('file', fileContent, filename);
    chunkForm.append('file_uid', uid);

    // Если требуется, добавим кастомных параметров в форму
    if(form_args !== undefined) {
        for (const [key, value] of Object.entries(form_args)) {
            chunkForm.append(key, value);
        }
    }

    request({
        method: 'post',
        url: url,
        headers: {
            'Content-Range': `bytes ${chunkStart}-${chunkEnd}/${filesize}`,
        },
        requestType: "form",
        data: chunkForm,
        success: (data) => {
            if (hasNextChunk) {
                let next_conf = {...conf}
                next_conf.uid = uid
                next_conf.chunkStart = chunkEnd

                uploadFile(next_conf)
            }

            else if (callback) {
                callback(data)
            }
        },
    })
}
